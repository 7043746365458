import type {
  Address,
  Cell,
  CellDep,
  Hash,
  HashType,
  HexString,
  RawTransaction,
  Script,
  Transaction,
  WitnessArgs,
} from '@ckb-lumos/lumos'
import type { LockScriptInfo } from '@ckb-lumos/common-scripts'

import { config as lumosConfig, helpers, Indexer, RPC } from '@ckb-lumos/lumos'

import { common, omnilock } from '@ckb-lumos/lumos/common-scripts'
import { indexer } from '@ckb-lumos/base'
import { blockchain, bytes } from '@ckb-lumos/lumos/codec'
import {
  getSporeScript,
  predefinedSporeConfigs,
  setSporeConfig,
  SporeConfig,
} from '@spore-sdk/core'
import { signRawTransaction } from '@joyid/ckb'

import {
  APP_KEYS,
  CKB_NATION_FEE_ADDRESS,
  NETWORK,
  StorageStyle,
} from '../constants'
import { Collector } from './Collector'
import { FeeAddressList, Fees } from './CkbMarket'
import { getDataPlaceholderHex, signLumosTx } from './CkbService'

class CkbController {
  static #instance: CkbController

  #config: typeof lumosConfig
  #sporeConfig: SporeConfig
  #services: {
    collector: Collector
    indexer: Indexer
    rpc: RPC
  }

  private constructor() {
    const config =
      NETWORK === 'mainnet' ? lumosConfig.MAINNET : lumosConfig.TESTNET

    lumosConfig.initializeConfig(config)

    this.#config = lumosConfig

    this.#sporeConfig = this.initializeSporeConfig()

    this.#services = {
      collector: new Collector({
        ckbNodeUrl: this.sporeConfig.ckbNodeUrl,
        ckbIndexerUrl: this.sporeConfig.ckbIndexerUrl,
      }),
      indexer: new Indexer(this.sporeConfig.ckbIndexerUrl),
      rpc: new RPC(this.sporeConfig.ckbNodeUrl),
    }
  }

  static getInstance(): CkbController {
    if (!CkbController.#instance) {
      CkbController.#instance = new CkbController()
    }

    return CkbController.#instance
  }

  private initializeSporeConfig() {
    const sporeConfig =
      NETWORK === 'mainnet'
        ? predefinedSporeConfigs.Mainnet
        : predefinedSporeConfigs.Testnet

    sporeConfig.lumos = this.config
    // sporeConfig.defaultTags = ['v2']

    // // Dependant on array position, should get patched in SDK
    // sporeConfig.scripts.Cluster.versions[1].behaviors = {
    //   clusterDataVersion: 'v1',
    // }

    setSporeConfig(sporeConfig)

    return sporeConfig
  }

  public get config(): lumosConfig.Config {
    return this.#config.getConfig()
  }

  public get sporeConfig(): SporeConfig {
    return this.#sporeConfig
  }

  public get rpc(): RPC {
    return this.#services.rpc
  }

  public get indexer(): Indexer {
    return this.#services.indexer
  }

  public get collector(): Collector {
    return this.#services.collector
  }

  public registerCustomScript(script: LockScriptInfo) {
    console.info('Registering Custom Lock Script w/ Lumos')
    // const scripts = [...commons.common.__tests__.getLockScriptInfos()._customInfos, script]

    common.registerCustomLockScriptInfos([script])
    // console.log('register?!?!?', commons.common.__tests__.getLockScriptInfos())

    // lumosConfig.initializeConfig(this.config)
    // this.#sporeConfig = this.initializeSporeConfig()

    // this.#config = lumosConfig

    // this.#sporeConfig.lumos = this.#config
    // setSporeConfig(this.#sporeConfig)
  }

  public getBalance = async (address: string) => {
    if (!address) {
      return 0n
    }

    const collector = this.#services.indexer.collector({
      lock: helpers.parseAddress(address),
      data: '0x',
    })

    let capacities = 0n
    for await (const cell of collector.collect()) {
      capacities += BigInt(cell.cellOutput.capacity)
    }

    return capacities
  }

  public async getCells(
    codeHash: Hash,
    args: HexString[],
    lockScript?: Script,
    hashType?: HashType,
  ): Promise<Cell[]> {
    if (!this.indexer.collector) throw new Error('CKB Collector not found!')

    hashType = hashType || 'data1'

    const order: 'asc' | 'desc' | undefined = 'desc'
    const tempArgs = [...args]

    const cellQuery: Array<indexer.QueryOptions> = [
      {
        type: {
          codeHash,
          hashType,
          args: tempArgs.splice(0, 1)[0],
        },
        order,
      },
    ]

    if (lockScript) cellQuery[0].lock = lockScript

    if (tempArgs.length > 0) {
      tempArgs.forEach((arg) =>
        cellQuery.push({
          ...cellQuery[0],
          // @ts-ignore
          type: {
            args: arg,
          },
        }),
      )
    }

    try {
      const cells: Cell[] = []

      for await (const cell of this.indexer
        .collector(cellQuery as any)
        .collect()) {
        cells.push(cell)
      }

      return cells
    } catch (error) {
      console.error('Error getting Cells::', error)
      throw new Error('Error getting Cells')
    }
  }

  public getLock = (address: Address, useLumos?: boolean): Script => {
    if (useLumos && address.startsWith('0x')) {
      return omnilock.createOmnilockScript(
        {
          auth: { flag: 'ETHEREUM', content: address },
        },
        { config: this.config },
      )
    } else {
      return helpers.parseAddress(address, { config: this.config })
    }
  }

  async sendTransaction(transaction?: Transaction) {
    if (!transaction) throw new Error('No Transaction to send!')
    if (!this?.rpc) throw new Error('RPC not configured!')

    let hash
    try {
      hash = await this.rpc.sendTransaction(transaction, 'passthrough')
    } catch (error) {
      console.error('rpc error', error)
      throw new Error('Error sending Transaction!')
    }

    const waitForTransaction = async (txHash: string) => {
      return new Promise(async (resolve) => {
        const transaction = await this.rpc.getTransaction(txHash)
        const { status } = transaction.txStatus
        if (status === 'committed') {
          resolve(txHash)
        } else {
          setTimeout(() => {
            resolve(waitForTransaction(txHash))
          }, 1500)
        }
      })
    }

    try {
      await waitForTransaction(hash)
    } catch {
      throw new Error('Error waiting on Transaction!')
    }

    return hash
  }

  public async prepareRawTransaction(
    rawTransaction: Transaction | RawTransaction,
    lockType: 'omnilock' | typeof APP_KEYS.joyid | null = null,
  ): Promise<helpers.TransactionSkeletonType> {
    try {
      let newOutputs: Cell[] = []

      if (rawTransaction.outputsData) {
        for (let i = 0; i < rawTransaction.outputs.length; i++) {
          const outputData = rawTransaction.outputsData[i]

          newOutputs.push({
            cellOutput: rawTransaction.outputs[i] as Cell['cellOutput'],
            data: outputData,
          })
        }
      } else if (rawTransaction.outputs) {
        newOutputs = rawTransaction.outputs as unknown as Cell[]
      }

      function createWitnessLockPlaceholder(signatureLength: number) {
        const serializedLength = omnilock.OmnilockWitnessLock.pack({
          signature: new Uint8Array(signatureLength),
        }).byteLength

        return bytes.hexify(new Uint8Array(serializedLength))
      }

      const newWitnessArgs: WitnessArgs = {
        lock: createWitnessLockPlaceholder(65),
      }

      const verifiedWitnesses: string[] = []

      ;(rawTransaction as Transaction).witnesses.forEach(
        (witness: string | {}) => {
          if (witness === '0x10000000100000001000000010000000')
            witness = bytes.hexify(blockchain.WitnessArgs.pack(newWitnessArgs))

          if (typeof witness === 'string') {
            verifiedWitnesses.push(witness)
          } else {
            const valueCheck = Object.values(witness).find((value) =>
              !value ? false : true,
            )

            if (!valueCheck) {
              verifiedWitnesses.push('0x')
              return
            }

            for (let [key, value] of Object.entries(witness)) {
              // @ts-ignore
              // if (type === 'lock' && value === '') witness[key] = '0x'
              if (value === '') witness[key] = '0x'
            }

            verifiedWitnesses.push(
              bytes.hexify(blockchain.WitnessArgs.pack(witness || {})),
            )
          }
        },
      )

      let txSkeleton = helpers.objectToTransactionSkeleton({
        cellProvider: this.#services.indexer,
        cellDeps: rawTransaction.cellDeps as CellDep[],
        inputs: [],
        outputs: newOutputs,
        witnesses: verifiedWitnesses,
        headerDeps: rawTransaction.headerDeps,
        // @ts-ignore
        fixedEntries: rawTransaction?.fixedEntries ?? [],
        // @ts-ignore
        signingEntries: rawTransaction?.signingEntries ?? [],
        inputSinces:
          // @ts-ignore
          rawTransaction?.inputSinces && rawTransaction?.inputSinces?.size !== 0
            ? // @ts-ignore
              rawTransaction?.inputSinces
            : { 0: '0x0' },
      })

      let index = 0
      let currentInputs = txSkeleton.get('inputs')

      for await (const input of rawTransaction.inputs) {
        // @ts-ignore
        if (!input?.previousOutput && !input?.outPoint)
          throw new Error('No valid outpoint found!')

        let generatedCell: Cell

        if (input.previousOutput) {
          const inputCell = await this.rpc.getLiveCell(
            input.previousOutput,
            true,
          )

          if (!inputCell || !inputCell.cell?.output)
            throw new Error('Could not get Input Cell!')

          generatedCell = {
            cellOutput: inputCell.cell.output,
            data: inputCell.cell?.data?.content ?? '0x',
            outPoint: input?.previousOutput,
          }
        } else {
          // @ts-ignore
          generatedCell = input
        }

        currentInputs = currentInputs.set(index, generatedCell)

        index += 1
      }
      txSkeleton = txSkeleton.set('inputs', currentInputs)

      if (lockType && lockType !== APP_KEYS.joyid) {
        const secp256k1LockScript = this.config.SCRIPTS['SECP256K1_BLAKE160']
        const omnilockLockScript = this.config.SCRIPTS['OMNILOCK']

        txSkeleton = helpers.addCellDep(txSkeleton, {
          depType: omnilockLockScript!.DEP_TYPE,
          outPoint: {
            txHash: omnilockLockScript!.TX_HASH,
            index: omnilockLockScript!.INDEX,
          },
        })

        txSkeleton = helpers.addCellDep(txSkeleton, {
          depType: secp256k1LockScript!.DEP_TYPE,
          outPoint: {
            txHash: secp256k1LockScript!.TX_HASH,
            index: secp256k1LockScript!.INDEX,
          },
        })
      }

      return txSkeleton
    } catch (error) {
      console.error('Error preparing raw Tx', error)
      throw new Error('Error preparing raw Tx')
    }
  }

  public prepareTransaction = (
    txSkeleton: helpers.TransactionSkeletonType,
    activeWallet: string,
  ) => {
    try {
      if (activeWallet !== APP_KEYS.joyid) {
        const secp256k1LockScript = this.config.SCRIPTS['SECP256K1_BLAKE160']
        const omnilockLockScript = this.config.SCRIPTS['OMNILOCK']

        txSkeleton = helpers.addCellDep(txSkeleton, {
          depType: omnilockLockScript!.DEP_TYPE,
          outPoint: {
            txHash: omnilockLockScript!.TX_HASH,
            index: omnilockLockScript!.INDEX,
          },
        })

        txSkeleton = helpers.addCellDep(txSkeleton, {
          depType: secp256k1LockScript!.DEP_TYPE,
          outPoint: {
            txHash: secp256k1LockScript!.TX_HASH,
            index: secp256k1LockScript!.INDEX,
          },
        })
      }

      const currentCellProvider = txSkeleton.get('cellProvider')
      if (!currentCellProvider || !currentCellProvider.collector)
        txSkeleton = txSkeleton.set('cellProvider', this.indexer)

      return txSkeleton
    } catch (error) {
      console.error('Error preparing Transaction::', error)
      throw new Error('Error preparing Transaction')
    }
  }

  public addFees = async (
    txSkeleton: helpers.TransactionSkeletonType,
    fees: Fees,
    addresses?: FeeAddressList,
  ) => {
    let devFee = !isNaN(Number(fees?.dev)) ? BigInt(fees.dev) : 0n
    let itemRoyalty = !isNaN(Number(fees?.itemRoyalty))
      ? BigInt(fees?.itemRoyalty ?? 0)
      : 0n
    let collectionRoyalty = !isNaN(Number(fees?.collectionRoyalty))
      ? BigInt(fees?.collectionRoyalty ?? 0)
      : 0n

    try {
      if (!!devFee && !itemRoyalty && !collectionRoyalty) {
        if (addresses && addresses?.currentUser) {
          txSkeleton = await common.injectCapacity(
            txSkeleton,
            [addresses.currentUser],
            devFee,
          )
        }

        txSkeleton = txSkeleton.update('outputs', (outputs) =>
          outputs.push({
            cellOutput: {
              capacity: `0x${devFee.toString(16)}`,
              lock: helpers.parseAddress(CKB_NATION_FEE_ADDRESS[NETWORK], {
                config: this.config,
              }),
            },
            data: '0x',
          }),
        )
      } else if (addresses && (itemRoyalty > 0n || collectionRoyalty > 0n)) {
        let deductCapacity = devFee

        if (devFee > 0n)
          txSkeleton = txSkeleton.update('outputs', (outputs) =>
            outputs.push({
              cellOutput: {
                capacity: `0x${devFee.toString(16)}`,
                lock: helpers.parseAddress(CKB_NATION_FEE_ADDRESS[NETWORK], {
                  config: this.config,
                }),
              },
              data: '0x',
            }),
          )

        if (itemRoyalty > 0n)
          txSkeleton = txSkeleton.update('outputs', (outputs) => {
            if (!addresses.itemCreator) return outputs
            deductCapacity += itemRoyalty

            return outputs.push({
              cellOutput: {
                capacity: `0x${itemRoyalty.toString(16)}`,
                lock: helpers.parseAddress(addresses.itemCreator, {
                  config: this.config,
                }),
              },
              data: '0x',
            })
          })

        if (collectionRoyalty > 0n)
          txSkeleton = txSkeleton.update('outputs', (outputs) => {
            if (!addresses.collectionCreator) return outputs
            deductCapacity += collectionRoyalty

            return outputs.push({
              cellOutput: {
                capacity: `0x${collectionRoyalty.toString(16)}`,
                lock: helpers.parseAddress(addresses.collectionCreator, {
                  config: this.config,
                }),
              },
              data: '0x',
            })
          })

        if (deductCapacity) {
          const outputs = txSkeleton.get('outputs')

          outputs.forEach((output, index) => {
            if (
              !output.cellOutput.type &&
              helpers.encodeToAddress(output.cellOutput.lock) ===
                addresses.seller
            ) {
              output.cellOutput.capacity = `0x${(BigInt(output.cellOutput.capacity) - deductCapacity).toString(16)}`

              outputs.set(index, output)
            }
          })

          txSkeleton = txSkeleton.set('outputs', outputs)
        }
      }

      return txSkeleton
      // let ourInputCell = txSkeleton.get('inputs').get(-1)
      // if (!ourInputCell) throw new Error('Error with Inputs!')

      // const currentOutputs = txSkeleton.get('outputs')
      // if (!currentOutputs) throw new Error('Error with Transaction, missing Outputs!')
      // const currentInputCapacity = BigInt(ourInputCell.cellOutput.capacity)

      // let outputsCapacity = BigInt(currentOutputs.get(-1)?.cellOutput.capacity ?? 0)
      // if (currentOutputs.size > 1) {
      //   outputsCapacity = outputsCapacity - BigInt(currentOutputs.get(0)?.cellOutput.capacity ?? 0)
      // }

      // if (currentInputCapacity < outputsCapacity + feeBigInt) {
      //   const mockTx = await commons.common.__tests__._commonTransfer(txSkeleton, [userAddress], feeBigInt, BigInt(0))
      //   const mockInputs = mockTx.txSkeleton.get('inputs')
      //   ourInputCell = mockInputs.get(-1)

      //   if (!ourInputCell) throw new Error('Error getting Capacity Cell')

      //   txSkeleton = txSkeleton.update('inputs', inputs => inputs.push(ourInputCell as Cell))
      // }

      // const lastOutput = currentOutputs.get(-1)
      // const outputCell = { ...ourInputCell }

      // // Extra for gas coverage
      // outputCell.cellOutput.capacity = `0x${(BigInt((lastOutput as Cell).cellOutput.capacity) - feeBigInt - BigInt(1000)).toString(16)}`

      // txSkeleton = txSkeleton.update('outputs', outputs => outputs.set(-1, outputCell))
      // txSkeleton = txSkeleton.update('outputs', outputs => outputs.push(walletFeeOutput))

      // return txSkeleton
    } catch (error) {
      console.error('Error adding expected Fees::', error)
      throw new Error('Error with adding fees')
    }
  }

  public getSporeTypeScript() {
    return getSporeScript(this.#sporeConfig, 'Spore')?.script
  }

  public calculateFee = (size: bigint, rate: bigint) =>
    ((size + 4n) * rate) / 1000n

  // Returns Shannon Value
  public calculateCellCapacity = (
    address: Address,
    type: Script,
    data: HexString,
  ) => {
    return helpers.minimalCellCapacity({
      cellOutput: {
        capacity: '0x0',
        lock: helpers.parseAddress(address),
        type,
      },
      data,
    })
  }

  // Returns Shannon Value
  public calculateSporeCapacity(
    address: Address,
    item: { [key: string]: any; storageStyle: StorageStyle },
    hasCluster: boolean,
  ) {
    const sporeCellCapacity = helpers.minimalCellCapacity({
      cellOutput: {
        capacity: '0x0',
        lock: helpers.parseAddress(address),
        // @ts-ignore
        type: {
          ...this.getSporeTypeScript(),
          args: '0x' + '0'.repeat(64), // Fill 32-byte TypeId placeholder
        },
      },
      data: getDataPlaceholderHex(item, hasCluster),
    })

    return sporeCellCapacity
  }

  public async signTx(txSkeleton: any, type: string, data: any) {
    let signedTx

    try {
      if (data.activeWallet !== APP_KEYS.joyid) {
        signedTx = await signLumosTx(txSkeleton, data?.address)
      } else {
        if (type === 'buy' || type === 'transfer') {
          signedTx = await signRawTransaction(
            // @ts-ignore
            helpers.createTransactionFromSkeleton(txSkeleton),
            data.address,
          )
        } else {
          console.log('signing, witnessIndexes used: ', data?.witnessIndexes)

          if (data?.witnessIndexes) {
            signedTx = await signRawTransaction(
              helpers.createTransactionFromSkeleton(txSkeleton),
              data.address,
              {
                witnessIndexes: data.witnessIndexes,
              },
            )
          } else {
            signedTx = await signRawTransaction(
              helpers.createTransactionFromSkeleton(txSkeleton),
              data.address,
            )
          }
        }
      }

      if (!signedTx) throw new Error('Invalid Signature!')
    } catch (error) {
      console.error('Error signing CKB Transaction::', error)
    }

    return signedTx
  }
}

export default CkbController.getInstance()
